import {Controller} from "@hotwired/stimulus"
import { get } from '@rails/request.js'


export default class extends Controller {

    static values = {
        page: String
    }
    connect() {}

    load(event) {
        event.preventDefault()
        // fetch()
        get('/?page=' + this.pageValue, {
            contentType: "text/vnd.turbo-stream.html",
            responseKind: "turbo-stream"
        })
    }
}